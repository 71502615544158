import React from "react";
import styled from "styled-components";
import { Card, Badge } from "react-bootstrap";

// import "./typography.module.css";

export const TitleXL = styled.h1`
  font-size: 2.5rem;
  @media only screen and (min-width: 700px) {
    font-size: 3rem;
  }
  /* font-weight: 700; */
`;

export const TitleCutout = styled.span`
  clip-path: polygon(4% 5%, 95% 12%, 98% 95%, 0 95%);
  color: white;
  display: inline-block;
  padding: 20px;
  background-color: #cc0a00;
  /* font-weight: 700; */
`;

export const Price = styled.p`
  font-size: 3em;
  line-height: 1em;
  margin-block-end: 0;
  padding-bottom: 0;
  font-weight: 800;
  text-align: center;
`;

export const PriceDetail = styled.p`
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
`;

export const Metric = styled.p`
  font-size: 1.2rem;
  line-height: 1rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0;
`;

export const PlanTitle = styled.p`
  font-size: 2rem;
  font-weight: 600;
  margin: 0;
  text-align: center;
  /* color: 'white'; */
`;

export const BaseCard = styled(Card)`
  && {
    margin-bottom: 1rem;
    border-radius: 10px;
    box-shadow: 0 7px 14px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    /* padding: 14px; */
  }
`;

export const StyledTag = styled(Badge)`
  font-size: 14px;
`;
