import React from "react";
import Seo from "../components/Seo";
import loadable from "@loadable/component";
import { Container } from "react-bootstrap";
// import Pricing from "../components/Pricing";
import Pricing from "../components/Pricing4";
import FeaturesTable from "../components/FeaturesTable"

// const FeaturesTable = loadable(() => import("../components/FeaturesTable"));

// import BetaPricing from "../components/BetaPricing";

// import JoinBeta from "../components/JoinBeta";
// import SignupForm from "../components/SignupForm";
// import Container from "react-bootstrap/Container";

const title = "Pricing - PlagiaShield";
const description = "Find the pricing that fits your business needs.";

const IndexPage = () => (
  <React.Fragment>
    <div className="pricing-header px-3 py-3 pt-md-2 pb-md-3 mx-auto text-center">
      <h1 className="display-4">Find the right plan</h1>
    </div>
    {/* <Container className="pb-4"> */}
    <Pricing />
    {/* <FeaturesTable /> */}
    <Container>
      <div style={{ margin: "3rem 0 2rem 0" }}>
        <h3>Need help?</h3>
        <p>
          Any question regarding the plans and their limits?{" "}
          <a href="/faq" target="_blank" rel="noopener noreferrer">
            {" "}
            Check our FAQ
          </a>{" "}
          or directly chat with us.
        </p>
      </div>

    </Container>

    {/* <p className="lead text-center">Try the public Beta now.</p> */}
    {/* <SignupForm /> */}
    {/* </Container> */}
    <Seo manualTitle={title} manualDescription={description} manualSlug="/pricing" />
  </React.Fragment>
);

export default IndexPage;
