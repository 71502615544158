import React from "react";
import { Button, Row, Col, Container, Card, Form, Badge } from "react-bootstrap";
import { BaseCard, Price, PriceDetail, Metric, PlanTitle } from "../Typography";
import { AiOutlineCheck } from 'react-icons/ai'
// interface PaidPlanProps {
//   target: String
//   description:String

// }

const Pricing = () => {
  // const isLarge = useIsLarge()

  return (
    <div>
      <Container fluid="xl" style={{ marginBottom: "2rem" }}>
        <Row className="justify-content-md-center" xs={24} sm={12} md={8}>
          <Col className="col-12 col-sm-6 col-lg-4">
            <Starter />
          </Col>
          <Col className="col-12 col-sm-6 col-lg-4">
            <Pro />
          </Col>
          <Col className="col-12 col-sm-6 col-lg-4">
            <Publisher />
          </Col>
          {/* <Col className="col-12 col-sm-6 col-lg-4">
            <Enterprise />
          </Col> */}
        </Row>
      </Container>
    </div>
  );
};

export default Pricing;

const track = (plan) => {
  // if (window.civchat) {
  //   window.userengage("event.site_plan_clicked", {
  //     plan_id: plan,
  //   });
  // }
  // UE track click and plan from pricing
};
const trackDemoRequest = (plan) => {
  // if (window.civchat) {
  //   // window.userengage("widget.open")
  //   window.userengage("event.site_demo_request", {
  //     plan_id: plan,
  //   });
  // }
  // UE track click and plan from pricing
};

const PriceTag = ({ price, showCurrency }) => {
  return (
    <div
      className="pricetag"
      style={{ textAlign: "center", marginBlockEnd: "0", lineHeight: "1.5em" }}
    >
      {(showCurrency == true) ?
        <sub
          style={{
            fontSize: "1.5em",
            fontWeight: "600",
            verticalAlign: "top",
            // lineHeight: "1.5em",
            marginRight: "0.3rem",
          }}
        >
          $
        </sub> : ""
      }
      <span style={{ fontSize: "3em", fontWeight: "800" }}>{price}</span>
      {/* <sub style={{ fontSize: "2em", fontWeight: "600", verticalAlign: "sub" }}>.{decimal}</sub> */}
    </div>
  );
};

const CTAStartNow = ({ plan }) => {
  return (
    <div className="d-grid mb-2">
      <Button
        className="mt-2"
        type="primary"
        href="https://app.plagiashield.com/app/signup"
        target="_blank"
        rel="noopener noreferrer"
        block
        size="lg"
        onClick={() => {
          track(plan);
        }}
      >
        Start Now
      </Button>
    </div>

  )
}

const CTARequestDemo = ({ plan }) => {
  return (
    <div className="d-grid mb-2">
      <Button
        className="mt-2"
        type="primary"
        href="https://calendly.com/plagiashield/30min"
        target="_blank"
        rel="noopener noreferrer"
        block
        size="lg"
      // onClick={() => {
      //   track(plan);
      //   trackDemoRequest(plan);
      // }}
      >
        Request Demo
      </Button>
    </div>

  )
}

const Title = ({ title }) => {
  return (
    <Card.Title
      style={{
        backgroundColor: "#cc0a00",
        color: "white",
        padding: "0.5rem 0rem",
      }}
    >
      <PlanTitle>{title}</PlanTitle>
    </Card.Title>
  )
}

const ShortDescription = ({ text }) => {
  return (
    <p className="text-muted" style={{ marginTop: '1rem', textAlign: 'center' }}>{text}</p>
  )
}

const targetHeight = "40rem";
const priceDetailHeight = "2rem";
const EmptyPriceDetail = () => <p style={{ height: priceDetailHeight }}></p>
const Target = ({ text }) => <PriceDetail>{text}</PriceDetail>
const StartsAt = () => <PriceDetail className="text-muted" style={{ height: priceDetailHeight, margin: "0.2rem" }}>starts at</PriceDetail>
const StartsAtEmpty = () => <PriceDetail className="text-muted" style={{ height: priceDetailHeight, margin: "0.2rem" }}></PriceDetail>
const CTADetail = () => <p className="text-muted" style={{ textAlign: 'center', height: priceDetailHeight, margin: "0.2rem" }}>7-day free trial</p>
const CTADetailEmpty = () => <p className="text-muted" style={{ textAlign: 'center', height: priceDetailHeight, margin: "0.2rem" }}></p>
const Overview = ({ text, bold }) => {
  if (text == null) {
    text = "Overview:"
  }
  if (bold === false) {
    return <p className="font-weight-bold mb-0 mt-1" >{text}</p>
  }
  return (
    <p className="font-weight-bold mb-0 mt-1" ><strong>{text}</strong></p>
  )
}

const MainItem = ({ text }) => <p style={{ margin: '0.2rem', textAlign: 'center' }}>{text}</p>
const shortDescriptionStarter = "Check ChatGPT and hundreds of documents"
const shortDescriptionPro = "Check entire domains and thousands of articles"
const shortDescriptionPublisher = "Protect your content at scale"
const shortDescriptionEnterprise = "Protect your content at scale."
const OverviewItem = ({ text }) => <p style={{ margin: '0.2rem' }}><AiOutlineCheck /> {text}</p>

const OverViewStarter = () => {
  return (
    <div>
      <div style={{ margin: '1rem' }}>
        <OverviewItem text="Unlimited ChatGPT checks" />
        <OverviewItem text="100 scans per month" />
        <p style={{ padding: '0.2rem', lineHeight: '1.5rem' }}>{" "}</p>
        <p style={{ padding: '0.2rem', lineHeight: '1.5rem' }}>{" "}</p>
        <p style={{ padding: '0.2rem', lineHeight: '1.5rem' }}>{" "}</p>
        {/* <MainItem text="1'000 pages per month" />
        <MainItem text="$20 per additional 1'000 pages" />
        <MainItem text="Domain scans: monthly" /> */}
      </div>
      <Overview text={"Addons:"} bold={false} />
      <MainItem text="$2 per additional 100 scans" />
      {/* <OverviewItem text="Chat support" />
      <OverviewItem text="DMCA Filler Chrome Extension" /> */}
    </div>
  )
}
const OverViewPro = () => {
  return (
    <div>
      <div style={{ margin: '1rem' }}>
        <OverviewItem text="Unlimited ChatGPT checks" />
        <OverviewItem text="1000 scans per month" />
        <OverviewItem text="includes 5 team members" />
        <OverviewItem text="Monthly domain plagiarism monitoring" />
        {/* <OverviewItem text="1000 scans per month" />
        <MainItem text="1'000 pages per month" />
        <MainItem text="Domain scans: monthly" /> */}
      </div>
      <Overview text={"Addons:"} bold={false} />
      <MainItem text="$3 per additional member" />
      <MainItem text="$20 per additional 1'000 pages" />
      {/* <OverviewItem text="Up to 5 domains" />
      <OverviewItem text="Chat support" />
      <OverviewItem text="DMCA Filler Chrome Extension" /> */}
    </div>
  )
}

const OverViewPublisher = () => {
  return (
    <div>
      <div style={{ margin: '1rem' }}>
        <Overview text="All in Pro plus:" bold={false} />
        <OverviewItem text="Weekly domain plagiarism monitoring" />
        <OverviewItem text="API Access" />
        <OverviewItem text="Additional billing options" />

      </div>
      <Overview text={"Addons:"} bold={false} />
      <MainItem text="Protect content behind paywalls" />
    </div>
  )
}

const OverviewEnterprise = () => {
  return (
    <div>
      <div style={{ margin: '1rem' }}>
        <br />
        <br />
        <br />
      </div>
      <MainItem text=" " />
      <MainItem text=" " />
      <MainItem text=" " />
      <Overview text="All in Publisher plus:" />
      <OverviewItem text="Protect content behind paywalls" />
      <OverviewItem text="Custom support" />
      <OverviewItem text="Additional billing options" />
    </div>
  )
}

const Starter = () => {
  return (
    <BaseCard >
      <Title title={"Starter"} />
      <Card.Body>
        <Target text="For personal use" />
        {/* <StartsAt /> */}
        <StartsAtEmpty />
        <PriceTag price={3} showCurrency={true} />
        <PriceDetail className="text-muted" style={{ height: priceDetailHeight }}>per month</PriceDetail>
        <CTAStartNow plan="starter" />
        {/* <CTADetail /> */}
        <ShortDescription text={shortDescriptionStarter} />
        <OverViewStarter />
      </Card.Body>
    </BaseCard>
  )
}
const Pro = () => {
  return (
    <BaseCard >
      <Title title={"Pro"} />
      <Card.Body>
        <Target text="For teams" />
        {/* <StartsAt /> */}
        <StartsAtEmpty />
        <PriceTag price={29} showCurrency={true} />
        <PriceDetail className="text-muted" style={{ height: priceDetailHeight }}>per month</PriceDetail>
        <CTAStartNow plan="pro" />
        {/* <CTADetail /> */}
        <ShortDescription text={shortDescriptionPro} />
        <OverViewPro />
      </Card.Body>
    </BaseCard>
  )
}

const Publisher = () => {
  return (
    <BaseCard >
      <Title title={"Publisher"} />
      <Card.Body>
        <Target text="For news leaders" />
        {/* <StartsAt /> */}
        {/* <PriceTag price={499} showCurrency={true} /> */}
        <StartsAtEmpty />
        <PriceTag price={"Custom"} showCurrency={false} />
        <EmptyPriceDetail />
        {/* <PriceDetail className="text-muted" style={{ height: priceDetailHeight }}>per month</PriceDetail> */}
        <CTARequestDemo plan="publisher" />
        {/* <CTADetailEmpty /> */}
        <ShortDescription text={shortDescriptionPublisher} />
        <OverViewPublisher />
      </Card.Body>
    </BaseCard >
  )
}

const Enterprise = () => {
  return (
    <BaseCard >
      <Title title={"Enterprise"} />
      <Card.Body>
        <Target text="For large teams and businesses" />
        <StartsAtEmpty />
        <PriceTag price={"Custom"} showCurrency={false} />
        <EmptyPriceDetail />
        <CTARequestDemo plan="enterprise" />
        <CTADetailEmpty />
        <ShortDescription text={shortDescriptionEnterprise} />
        <OverviewEnterprise />
      </Card.Body>
    </BaseCard>
  )
}